import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./views/global/Topbar";
import GNIECloudFiles from "./views/files/files";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/footer";
import Sidebar from "./components/sidebar";
import GNIECloudAnimation from "./components/animation";
import LanguageProvider from './contexts/languageProvider'
function App() {
  const [theme, colorMode] = useMode();

  return (
      <LanguageProvider>
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app" style={{ display: 'flex' }}>
          <Sidebar />
          <main className="content" style={{ flex: 1 }}>
            <Routes>
            <Route
                path="/:country/:organization/:team/files"
                element={<GNIECloudFiles />}
              />
            <Route
                path="/:country/:organization/files"
                element={<GNIECloudFiles />}
              />
            <Route path="/:country/files" element={<GNIECloudFiles />} />
              {/* Fallback route */}
              <Route path="/" element={<GNIECloudAnimation/>} />
            </Routes>
          </main>
          </div>

      </ThemeProvider>
      </ColorModeContext.Provider>
      </LanguageProvider>
  );
}

export default App;