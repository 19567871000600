const translations = {
  en: {
    "technologyOperations": "Technology Operations",
    "globalConsumerServices": "Global Consumer Services",
    "incidentManagementOperations":"Incident Management Operations",
    "gaming": "Gaming",
    "retailer": "Retailer",
    "gnieCloudFiles": "GNIECloud Files",
    "unitedStates": "United States",
    "welcomeToGnieCloudFiles": "Welcome to GNIECloud Files",
    "contactCenter": "Contact Center",
    "fileManagement": "File Management",
    "articleID": "Article ID",
    "fileName": "File Name",
    "fileType": "File Type",
    "actions": "Actions",
    "createdBy": "Created By",
    "uploadFile": "Upload File",
    "yourName": "Your Name",
    "chooseFile": "Choose File",
    "upload": "Upload",
    "cancel": "Cancel",
    "fileDeletedSuccessfully": "File deleted successfully!",
    "errorDeletingFile": "Error deleting file.",
    "fileLinkCopiedToClipboard": "File link copied to clipboard.",
    "errorDownloadingFilePleaseTryAgain": "Error downloading file. Please try again.",
    "fileUploadedSuccessfully": "File uploaded successfully!",
    "errorUploadingFilePleaseTryAgain": "Error uploading file. Please try again.",
    "pleaseFillInAllFieldsIncludingYourName": "Please fill in all the required fields, including your name."
  },
  es: {
    "technologyOperations": "Operaciones Tecnológicas",
    "globalConsumerServices": "Servicios globales al consumidor",
    "incidentManagementOperations":"Operaciones de gestión de incidentes",
    "gaming": "Lotería",
    "retailer": "Minorista",
    "gnieCloudFiles": "GNIECloud Files",
    "unitedStates": "Estados Unidos",
    "welcomeToGnieCloudFiles": "Bienvenido a GNIECloud Files",
    "contactCenter": "Centro de Contacto",
    "fileManagement": "Gestión de Archivos",
    "articleID": "ID de Artículo",
    "fileName": "Nombre del Archivo",
    "fileType": "Tipo de Archivo",
    "actions": "Acciones",
    "createdBy": "Creado Por",
    "uploadFile": "Subir Archivo",
    "yourName": "Tu Nombre",
    "chooseFile": "Elegir Archivo",
    "upload": "Subir",
    "cancel": "Cancelar",
    "fileDeletedSuccessfully": "¡Archivo eliminado con éxito!",
    "errorDeletingFile": "Error al eliminar el archivo.",
    "fileLinkCopiedToClipboard": "Enlace de archivo copiado al portapapeles.",
    "errorDownloadingFilePleaseTryAgain": "Error al descargar el archivo. Por favor, inténtalo de nuevo.",
    "fileUploadedSuccessfully": "¡Archivo subido con éxito!",
    "errorUploadingFilePleaseTryAgain": "Error al subir el archivo. Por favor, inténtalo de nuevo.",
    "pleaseFillInAllFieldsIncludingYourName": "Por favor, rellena todos los campos obligatorios, incluido tu nombre."
  }
};

export default translations;