// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "  html,\r\n  body,\r\n  #root,\r\n  .app,\r\n  .content {\r\n\r\n    height:100%;\r\n    width: 100%;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    overflow-x: hidden;\r\n  }\r\n\r\n  .app{\r\n\r\n    display: flex;\r\n    position: relative;\r\n\r\n  }\r\n\r\n  ::-webkit-scrollbar {\r\n\r\n    width: 10px;\r\n\r\n  }\r\n\r\n  /* Track */\r\n  ::-webkit-scrollbar-track{\r\n\r\n    background: #e0e0e0;\r\n  }\r\n\r\n  /* Handle */\r\n\r\n  ::-webkit-scrollbar-thumb{\r\n\r\n    background: #888;\r\n\r\n  }\r\n\r\n  /* Handle on Hover */\r\n\r\n  ::-webkit-scrollbar-thumb:hover {\r\n\r\n    background: #555;\r\n\r\n  }\r\n  ", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"EAEE;;;;;;IAME,WAAW;IACX,WAAW;IACX,0CAA0C;IAC1C,kBAAkB;EACpB;;EAEA;;IAEE,aAAa;IACb,kBAAkB;;EAEpB;;EAEA;;IAEE,WAAW;;EAEb;;EAEA,UAAU;EACV;;IAEE,mBAAmB;EACrB;;EAEA,WAAW;;EAEX;;IAEE,gBAAgB;;EAElB;;EAEA,oBAAoB;;EAEpB;;IAEE,gBAAgB;;EAElB","sourcesContent":["  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');\r\n\r\n  html,\r\n  body,\r\n  #root,\r\n  .app,\r\n  .content {\r\n\r\n    height:100%;\r\n    width: 100%;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    overflow-x: hidden;\r\n  }\r\n\r\n  .app{\r\n\r\n    display: flex;\r\n    position: relative;\r\n\r\n  }\r\n\r\n  ::-webkit-scrollbar {\r\n\r\n    width: 10px;\r\n\r\n  }\r\n\r\n  /* Track */\r\n  ::-webkit-scrollbar-track{\r\n\r\n    background: #e0e0e0;\r\n  }\r\n\r\n  /* Handle */\r\n\r\n  ::-webkit-scrollbar-thumb{\r\n\r\n    background: #888;\r\n\r\n  }\r\n\r\n  /* Handle on Hover */\r\n\r\n  ::-webkit-scrollbar-thumb:hover {\r\n\r\n    background: #555;\r\n\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
