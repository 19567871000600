import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import './animation.css'
import IGTSphere from '../assets/IGTSphere.png'
import { LanguageContext } from '../contexts/languageContext';

  const customTheme = {
        colors: {
            primaryColor: '#0c51a1',
            secondaryColor: '#ffa600'
        },
    };


const GNIECloudAnimation = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [color, setColor] = useState(customTheme.colors.primaryColor);
    const t = useContext(LanguageContext);


 useEffect(() => {
    setTimeout(() => {
        setColor(customTheme.colors.secondaryColor);
        setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => {
                setIsLoaded(true);
            }, 1000);
        }, 2000);  // let's say it will take 2 seconds for the color transition
    }, 1000);  // change color after 1 second of loading
}, []);


    const loadedAnimation = useSpring({
    transform: isLoaded ? 'translateX(0%)' : 'translateX(100%)',
    opacity: isLoaded ? 1 : 0,
});

    
    return (
        <div className="container">
            {isLoading && (
                <div>
                    <CircularProgress size={80} className="circle spin-animation" style={{ color }} />
                </div>
            )}
         <animated.div className="title-container" style={loadedAnimation}>
            <Typography variant='h1' sx={{ color: customTheme.colors.primaryColor, marginRight: '15px', fontSize: '55px', fontWeight: "bold"  }}>{t.welcomeToGnieCloudFiles}</Typography>
            <div className="spark-container">
                <img src={IGTSphere} alt="IGT Sphere" className='logo'/>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
                <div className="spark"></div>
            </div>
        </animated.div>
        </div>
    );
};

export default GNIECloudAnimation;
