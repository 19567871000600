import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import './datagridstyles.css';
import MaintenanceNotification from '../../components/maintenance';
import { LanguageContext } from '../../contexts/languageContext';

const GNIECloudFiles = () => {
  const { country, organization, team } = useParams();
  const [fileData, setFileData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [articleID, setArticleID] = useState('');
  const [userName, setUserName] = useState('');
  const t = useContext(LanguageContext);
  const location = useLocation();
  const [pageName, setPageName] = useState('Default Page Name');

  const getApiUrl = (endpoint, fileName = '') => {
    return `https://api.gniecloud.com/v1/filemanagement/${country}/${organization}${
      team ? `/${team}` : ''
    }/${endpoint}/${fileName}`;
  };

  const handleDelete = async file => {
    const apiUrl = getApiUrl('files', file.fileName);
    try {
      await axios.delete(apiUrl);
      const updatedFileData = fileData.filter(f => f.id !== file.id);
      setFileData(updatedFileData);
      alert(t.fileDeletedSuccessfully);
    } catch (error) {
      console.error(
        'Error deleting the file:',
        error.response ? error.response.data : error
      );
      alert(
        t.errorDeletingFile +
          (error.response ? error.response.data.message : error.message)
      );
    }
  };

  const copyFileLink = row => {
    const fileLink = row.link;
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.opacity = 0;
    textArea.value = fileLink;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert(t.fileLinkCopiedToClipboard);
  };

  const handleDownload = row => {
    const fileLink = row.link;
    axios
      .get(fileLink, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Download error:', error);
        alert(t.errorDownloadingFilePleaseTryAgain);
      });
  };

  const handleSubmit = async () => {
    if (selectedFile && articleID && userName) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('articleID', articleID);
      formData.append('userName', userName);
      const apiUrl = getApiUrl('files/upload');

      axios
        .post(apiUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          setOpen(false);
          fetchFileData();
          alert(t.fileUploadedSuccessfully);
        })
        .catch(error => {
          alert(t.errorUploadingFilePleaseTryAgain);
        });
    } else {
      alert(t.pleaseFillInAllFieldsIncludingYourName);
    }
  };

  const fetchFileData = async () => {
    const apiUrl = getApiUrl('files');
    axios
      .get(apiUrl)
      .then(response => {
        setFileData(response.data);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
      });
  };

  useEffect(() => {
    fetchFileData();
  }, [country, organization, team]);

  useEffect(() => {
    const routeToPageName = {
      '/us/cc/imo/files': `${t.gnieCloudFiles} ${t.incidentManagementOperations}`,
      '/us/cc/retailer/files': `${t.gnieCloudFiles} ${t.retailer}`,
      '/us/to/files': `${t.gnieCloudFiles} ${t.technologyOperations}`,
      '/cr/cc/files': `${t.gnieCloudFiles} Costa Rica`,
      '/us/gcs/files': `${t.gnieCloudFiles} ${t.globalConsumerServices}`,
      '/us/fst/files': `${t.gnieCloudFiles} Field Service Technicians`,
      // Add more route-to-pageName mappings as needed
    };

    const currentRoute = location.pathname;
    setPageName(routeToPageName[currentRoute] || 'Default Page Name');
  }, [location]);
  const columns = getColumns(t, copyFileLink, handleDownload, handleDelete);

  return (
    <>
      <AppBar position='static' sx={{ backgroundColor: '#0c51a1' }}>
        <Toolbar>
          <Typography variant='h3' style={{ flexGrow: 1, color: '#ffa300' }}>
            <Box
              component='span'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {pageName}
            </Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <MaintenanceNotification />
      <Box p={4}>
        <Button
          variant='outlined'
          sx={{
            fontSize: '15px',
            marginBottom: '15px',
            height: '45px',
            width: '150px',
            backgroundColor: '#0c51a1',
            color: 'white',
            '&:hover': {
              backgroundColor: '#1b9ddb',
            },
          }}
          onClick={() => setOpen(true)}
          startIcon={<CloudUploadIcon />}
        >
          {t.uploadFile}
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle
            style={{ color: '#ff671f', fontSize: '25px', fontWeight: 'bold' }}
          >
            {t.uploadFile}
          </DialogTitle>
          <DialogContent sx={{ height: '300px' }}>
            <TextField
              margin='dense'
              id='articleID'
              label={t.articleID}
              type='text'
              fullWidth
              value={articleID}
              onChange={e => setArticleID(e.target.value)}
            />
            <TextField
              autoFocus
              margin='dense'
              id='userName'
              label={t.yourName}
              type='text'
              fullWidth
              value={userName}
              onChange={e => setUserName(e.target.value)}
              sx={{ marginTop: '10px' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                accept='image/*, .pdf, .txt, .xlsx, .csv, .doc, .docx'
                style={{ display: 'none' }}
                id='file-upload'
                type='file'
                onChange={e => setSelectedFile(e.target.files[0])}
              />
              <label htmlFor='file-upload'>
                <Button
                  component='span'
                  variant='contained'
                  sx={{
                    fontSize: '15px',
                    backgroundColor: '#0c51a1',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#1b9ddb',
                    },
                  }}
                >
                  {t.chooseFile}
                </Button>
              </label>
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                onClick={() => handleSubmit(userName)}
                variant='contained'
                sx={{
                  fontSize: '15px',
                  backgroundColor: '#ff671f',
                  color: 'white',
                  marginRight: '15px',
                  '&:hover': {
                    backgroundColor: '#ffa300',
                  },
                }}
              >
                {t.upload}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                variant='contained'
                sx={{ background: 'grey', fontSize: '15px' }}
              >
                {t.cancel}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Box height={700} width='85vw' sx={{ margiLeft: '15px' }}>
          <DataGrid
            rows={fileData}
            columns={columns}
            pageSize={5}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
};

const getColumns = (t, copyFileLink, handleDownload, handleDelete) => {
  return [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'articleID', headerName: t.articleID, flex: 1 },
    { field: 'fileName', headerName: t.fileName, flex: 2 },
    { field: 'fileType', headerName: t.fileType, flex: 1 },
    {
      field: 'actions',
      headerName: t.actions,
      flex: 1,
      renderCell: params => (
        <Box display='flex' alignItems='center'>
          <Tooltip title='Copy File Link'>
            <IconButton onClick={() => copyFileLink(params.row)} size='small'>
              <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Download File'>
            <IconButton onClick={() => handleDownload(params.row)} size='small'>
              <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete File'>
            <IconButton
              onClick={() => handleDelete(params.row)}
              size='small'
              color='error'
            >
              <DeleteForeverIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    { field: 'createdBy', headerName: t.createdBy, flex: 1 },
  ];
};

export default GNIECloudFiles;
