import {Box, IconButton, useTheme} from "@mui/material"
import { useContext } from "react";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { ColorModeContext, tokens } from "../../theme";

const Topbar =() => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    return(<Box display="flex" justifyContent="space-between" p={2}>
        {/* Search Bar */}
    <Box
    display="flex"
    backgroundColor={colors.primary[400]}
    borderRadius="3px"
    >
        {/* <InputBase sx={{ml: 2, flex: 1}} placeholder="Search" />
        <IconButton type="button" sx={{p: 1}}>
            <SearchIcon/>
        </IconButton>   */}
    </Box>
    {/* Icons */}
    <Box display="flex" justifyContent="space-between" p={2}>
        <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === 'dark' ? (
                <DarkModeOutlinedIcon />
            ): (
                <LightModeOutlinedIcon />
            )
        }
        </IconButton>
        {/* <IconButton>
            <NotificationsOutlinedIcon/>
        </IconButton> */}
        {/* <IconButton>
            <PersonIcon />
        </IconButton> */}
        {/* <IconButton>
            <SettingsIcon />
        </IconButton> */}
    </Box>
</Box>
    
);
};

export default Topbar;