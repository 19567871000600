import React, { useState, useContext } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import './sidebar.css';
import { LanguageContext } from '../contexts/languageContext';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const t = useContext(LanguageContext);

  return (
    <div>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          <div
            style={{
              textAlign: isCollapsed ? 'center' : 'right',
              padding: '10px',
              transition: 'text-align 0.3s',
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <ChevronRightIcon
                  sx={{ color: '#ffa600', fontSize: '30px !important' }}
                />
              ) : (
                <MenuOpenIcon
                  sx={{ color: '#ffa600', fontSize: '30px !important' }}
                />
              )}
            </IconButton>
          </div>
          {/* United States */}
          <SubMenu title={t.unitedStates}>
            <SubMenu title={t.contactCenter}>
              <MenuItem>
                <a href='/us/cc/imo/files'>{t.incidentManagementOperations}</a>
              </MenuItem>
              <MenuItem>
                <a href='/us/cc/retailer/files'>{t.retailer}</a>
              </MenuItem>
            </SubMenu>
            <MenuItem>
              <a href='/us/fst/files'>Field Service Technicians</a>
            </MenuItem>
            <MenuItem>
              <a href='/us/gcs/files'>{t.globalConsumerServices}</a>
            </MenuItem>
            <MenuItem>
              <a href='/us/to/files'>{t.technologyOperations}</a>
            </MenuItem>
          </SubMenu>
          {/* Costa Rica */}
          <SubMenu title='Costa Rica'>
            <MenuItem>
              <a href='/cr/cc/files'>{t.contactCenter}</a>
            </MenuItem>
          </SubMenu>
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
