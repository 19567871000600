// Leverages default browser language to provide accurate translations
import React, { useState, useEffect } from 'react';
import { LanguageContext } from './languageContext';
import translations from './translations/translations';

const languageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default to English

  useEffect(() => {
    const browserLang = navigator.language.split('-')[0];
    if (translations[browserLang]) {
      setLanguage(browserLang);
    }
  }, []);

  return (
    <LanguageContext.Provider value={translations[language]}>
      {children}
    </LanguageContext.Provider>
  );
};

export default languageProvider;
