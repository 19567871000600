import React from 'react';
import IGTLogo from '../assets/IGT_Logo_White.png'

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#0c51a1', color: '#ffffff', padding: '1rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ flex: '0 0 33.33%', padding: '1rem' }}>
                     <img src={IGTLogo} style={{height:'55px',  width: '150px'}}/>
                    <p>r.23.01.09 b30</p>
                    <p>This site was optimized for desktop. </p>
                    <p>&copy; {new Date().getFullYear()} IGT. All rights reserved.</p>
                </div>
                <div style={{ flex: '0 0 25%', padding: '1rem' }}>
                </div>
                <div style={{ flex: '0 0 25%', padding: '1rem' }}>
                    <h6>Links</h6>
                    <a href="https://www.igt.com/explore-igt/about-igt" style={{ textDecoration: 'none', color: 'white' }} target="_blank" rel="noreferrer">
                        <p>About Us</p>
                    </a>
                    <a href="https://igt-apps.easyvista.com/index.php?timestamp=1617993446970&name=com.igt.5df1f9d1a49a3&page=606cd7bb217b1" style={{ textDecoration: 'none', color: 'white' }} target="_blank" rel="noreferrer">
                        <p>EasyVista</p>
                    </a>
                    <a href="./admin" style={{ textDecoration: 'none', color: 'white' }} target="_blank" rel="noreferrer">
                        <p>Admin Portal</p>
                    </a>
                </div>
                <div style={{ flex: '0 0 16.67%', padding: '1rem' }}>
                    <h6>Contact Us</h6>
                    <p>
                        <a style={{ color: 'white' }} href="tel:+18663131516">+1 866-313-1516</a>
                    </p>
                    <p>Technology Operations</p>
                    <a href="mailto:GNIE-Administrators@IGT.com?subject=GNIE%20Inquiry" style={{ textDecoration: 'none', color: 'white' }}>Email Us</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
